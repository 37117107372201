<template>
    <section class="ScrollerGallery" :id="sliceId">
        <h2 v-if="slice.primary.section_title" class="ScrollerGallery__title">
            <FillTextEffect
                :text="slice.primary.section_title"
                :trigger-container="'#' + sliceId"
                bottom-position="center"
            />
        </h2>
        <ul
            v-for="(row, rowIndex) in rows"
            class="ScrollerGallery__list"
            :class="['ScrollerGallery__list--' + (Number(rowIndex) + 1)]"
            :id="buildRowId(rowIndex)"
            :key="'row-' + index"
        >
            <li
                v-for="(item, itemIndex) in row"
                class="ScrollerGallery__list-item"
                :key="'row-item-' + itemIndex"
            >
                <figure class="ScrollerGallery__image-wrapper">
                    <SmartImage
                        class="ScrollerGallery__image"
                        :url="item.image.url || ''"
                        :srcs="[
                            { width: 400 },
                            { width: 800 },
                            { width: 1000 },
                            { width: 1300 },
                        ]"
                        positioning="relative"
                        :width="item.image?.dimensions?.width || 466"
                        :height="280"
                        :title="item.image.alt || ''"
                        :alt="item.image.alt || ''"
                    />
                    <figcaption
                        class="ScrollerGallery__caption"
                        v-if="item.image.alt"
                    >
                        {{ item.image.alt }}
                    </figcaption>
                </figure>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.ScrollerGallerySlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-') + '-scroller-gallery'

const filteredItems = computed(() => {
    return props.slice.items.filter((item) => item.image?.url)
})

function buildRowId(rowIndex: number) {
    return props.slice.id.replace('$', '-') + '-row-' + (Number(rowIndex) + 1)
}

const row1 = computed(() => {
    if (filteredItems.value.length <= 6) {
        return filteredItems.value
    }

    return filteredItems.value.slice(0, Math.ceil(props.slice.items.length) / 2)
})

const row2 = computed(() => {
    if (filteredItems.value.length <= 6) {
        return []
    }

    return filteredItems.value.slice(-Math.ceil(props.slice.items.length) / 2)
})

const mergedRow1 = computed(() => {
    if (!row2.value.length) return [...row1.value, ...row1.value, ...row1.value]

    return [...row1.value, ...row2.value, ...row1.value, ...row2.value]
})

const mergedRow2 = computed(() => {
    if (!row2.value.length) return []

    return [...row2.value, ...row1.value, ...row2.value, ...row1.value]
})

const rows = computed(() => [mergedRow1.value, mergedRow2.value])

onMounted(() => {
    const row1Id = buildRowId(0)
    const row2Id = buildRowId(1)
    const { gsap } = useGsap()

    gsap.to('#' + row1Id, {
        scrollTrigger: {
            trigger: '#' + row1Id,
            scrub: 2,
            start: '-100% center',
            end: '300% top',
        },
        ease: 'ease',
        x: '-70%',
    })

    if (row2.value.length) {
        gsap.to('#' + row2Id, {
            scrollTrigger: {
                trigger: '#' + row2Id,
                scrub: 2,
                start: '-210% center',
                end: '300% top',
            },
            ease: 'ease',
            x: '70%',
        })
    }
})
</script>

<style lang="scss">
.TextHero + .ScrollerGallery {
    margin-top: 0;
}

.ScrollerGallery {
    @include grid;

    grid-column: 1 / span 24;
    overflow: hidden;
    margin-top: 8rem;

    @include medium-up {
        margin-top: 11.25rem;
        row-gap: 2rem;
    }
}

.ScrollerGallery__title {
    @include heading-2;

    grid-column: 2 / span 22;
    max-width: 46rem;

    @include small-only {
        margin-bottom: 2rem;
    }
}

.ScrollerGallery__list {
    grid-column: 1 / span 24;
    display: flex;
    gap: 1rem;
    width: 100%;

    @include medium-up {
        gap: 2rem;
    }
}

.ScrollerGallery__list--1 {
    position: relative;
    right: 0%;
}

.ScrollerGallery__list--1:has(+ .ScrollerGallery__list--2) {
    @include small-only {
        margin-bottom: 1rem;
    }
}

.ScrollerGallery__list--2 {
    position: relative;
    right: 130%;

    @include medium-only {
        right: 150%;
    }
}

.ScrollerGallery__list-item {
    width: 50%;
    flex-shrink: 0;

    @include medium-up {
        width: 40%;
    }

    @include large-up {
        width: 33%;
    }
}

.ScrollerGallery__image {
    width: 100%;
}

.ScrollerGallery__image-wrapper {
    position: relative;
}

.ScrollerGallery__caption {
    display: none;

    @include medium-up {
        @include body-sm;

        display: block;
        position: absolute;
        bottom: 0;
        background-color: var(--lime-green);
        z-index: 1;
        color: var(--black);
        padding: 0.25rem 0.75rem;
        font-style: italic;
    }
}
</style>
